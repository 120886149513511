// Copyright © 2024 Christopher Laux <mail@chrislaux.com>

import $ from 'jquery';
import DataTable from 'datatables.net-dt';
import 'datatables.mark.js';
import 'datatables.net-buttons-dt';
import jszip from 'jszip';
window.JSZip = jszip;
import pdfMake from 'pdfmake';
import 'datatables.net-buttons/js/buttons.html5.js';
import 'datatables.net-buttons/js/buttons.print.js';
import hsl from 'hsl-to-hex';


function hashToColor(str) {
    let hash = 0;

    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    return hsl(hash % 360, 80, 30);
}

export function setupTable(preview = false) {
    let buttons = [];

    if (!preview) {
        buttons = [
            'copy', 'csv', 'excel', 'print'
        ];
    }

    const table = new DataTable('#datatable', {
        ajax: {
            url: process.env.AJAX_BASE + '/podcasts',
            data: function (d) {
                d.preview = preview;

                let cat = $('#categoryHeader select').val();

                if(cat !== undefined && cat !== null && cat !== '')
                    d.category = encodeURI(cat);
            }
        },
        processing: true,
        serverSide: true,
        mark: true,
        dom: 'Blfrtip',
        buttons: buttons,
        columns: [
            { data: 'Title' },
            {
                data: 'Link',
                orderable: false,
                searchable: false
            },
            {
                data: 'RssUrl',
                orderable: false,
                searchable: false
            },
            {
                data: 'OwnerName',
                render: function (data, type, row) {
                    if (data.trim().length > 0)
                        return data;
                    else
                        return row.Author;
                }
            },
            {
                data: 'OwnerEmail',
                orderable: false,
                searchable: false
            },
            {
                data: 'PubDateTime',
                searchable: false,
                render: function (data, type, row) {
                    let date = new Date(data);
                    return date.toLocaleDateString();
                }
            },
            {
                data: 'Categories',
                orderable: false,
                render: function (data, type, row) {
                    let categoriesArray = data.split(", ");

                    return categoriesArray.map(category => {
                        category = category.trim().replace(/^"|"$/g, '');
                        let color = hashToColor(category);

                        if (category.length > 0) {
                            return `<span class="tag" style="background-color: ${color}">${category}</span>`;
                        } else {
                            return '';
                        }
                    }).join(' ');
                }
            },
            {
                data: 'Description',
                orderable: false,
                className: 'description-column',
                render: function (data, type, row) {
                    let truncated = data.length > 80 ? data.substr(0, 80) + ' ...' : data;

                    return `<span class="description" title="${data}">${truncated}</span>`;
                }
            },
            { data: 'Sponsors' }
        ]
    })

    var select = $('<select><option value="nil">Select category</option></select>')
        .appendTo('#categoryHeader')
        .on('change', function () {
            table.ajax.reload();
        });

    getCategories().then(data => {
        data.forEach(category => {
            select.append('<option value="' + category + '">' + category + '</option>');
        });
    });

    return table;
}

function getCategories() {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: process.env.AJAX_BASE + '/categories',
            method: 'GET',
            success: function(data) {
                resolve(data);
            },
            error: function(error) {
                reject(error);
            }
        });
    });
}

export function getUserData() {
    return new Promise((resolve, reject) => {
        $.ajax({
            url: process.env.AUTH_BASE + '/user',
            method: 'GET',
            xhrFields: {
                withCredentials: true
            },
            success: function(data) {
                resolve(data);
            },
            error: function(error) {
                reject(error);
            }
        });
    });
}
